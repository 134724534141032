import React from "react";
import './sideNavBar.scss';
import logo from "../../assets/images/finalyes.png";
import { Link, useLocation } from "react-router-dom";

function SideNavBar() {
  const location = useLocation();

  return (
    <div className="admin-side-nav"> 
      <div className="left-side">
        <img src={logo} alt="Logo" />
        <Link to="/" className={`link ${location.pathname === "/" ? "active" : ""}`}>Home</Link>
        <Link to="/submitted-cases" className={`link ${location.pathname === "/submitted-cases" ? "active" : ""}`}>Submitted Cases</Link>
        <Link to="/archived-cases" className={`link ${location.pathname === "/archived-cases" ? "active" : ""}`}>Archived Cases</Link>
        <Link to="/users" className={`link ${location.pathname === "/users" ? "active" : ""}`}>Users</Link>
        <Link to="/messages" className={`link ${location.pathname === "/messages" ? "active" : ""}`}>Messages</Link>
      </div>
    </div>
  );
}

export default SideNavBar;
