import React, {  } from "react";
import "./dashboard.scss";
import DashboardNavBar from "./dashboardNavBar.jsx";
import SideNavBar from "./sideNavBar.jsx";
import Stats from "./stats.jsx";
function Dashboard() {
  return (
    <>
      <DashboardNavBar />
      <div className="dash-body">
        <SideNavBar />
      <Stats />
      </div>
      
    </>
  );
}

export default Dashboard;
