import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const updateCase = createAsyncThunk(
  "case/updateCase",
  async ({ id, data }, { rejectWithValue }) => {
    console.log(data,"bbbbbbbbbbbbbb")
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(`${BACKEND_URL}/cases/${id}`,data, {
        headers: { Authorization: `Bearer ${token}` },
        
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to update case");
    }
  }
);

export const getSingleCase = createAsyncThunk(
  "case/getSingleCase",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${BACKEND_URL}/cases/singleCase/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Assuming the API returns data in the structure { data: { ...caseData } }
      console.log("data from single case",response.data.data)
      return response.data.data; // Adjust this based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to get case");
    }
  }
);

const initialState = {
  caseData: {}, // caseData as an object, assuming a single case is fetched
  loading: false,
  error: null,
};

const updateCaseSlice = createSlice({
  name: "updateCase",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateCase.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCase.fulfilled, (state, action) => {
        state.caseData = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(updateCase.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getSingleCase.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSingleCase.fulfilled, (state, action) => {
        state.caseData = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getSingleCase.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default updateCaseSlice.reducer;
