import React from "react";
import HomeNav from "../homenav/HomeNav.jsx";
import "./welcomestyles.scss";
import "../chardipslay/chardisplay.scss";
import Slideshow from "../himeslider/homeslides.jsx";
import CharacterDisplay from "../chardipslay/CharacterDisplay.jsx";
import AboutUs from "../aboutus/aboutus.jsx";
import AllTeam from "../team/team.jsx";
import Services from "../services/services.jsx";
import Footer from "../footer/footer.jsx";
import bgimg from "../../assets/images/bg.png";

const Welcome = () => {
  return (
    <div>
      <div
        className="welcome-div"
        style={{
          backgroundImage: `url(${bgimg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="homenav-component">
          <HomeNav />
        </div>

        {/* <hr /> */}
        <div className="chardisplay">
          <CharacterDisplay />
        </div>
        <div className="slide-show">
        <Slideshow />  
        </div>
        
      </div>
      <AboutUs />

      <Services />
      <AllTeam />

      <Footer />
    </div>
  );
};

export default Welcome;
