import React, { useState, useEffect } from "react";
import "./homenavstyles.scss";
import logo from "../../assets/images/newLogo.jpg";
import bgimg from "../../assets/images/bg.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../../redux/slices/userSlice";
import { toast, ToastContainer } from "react-toastify";
function HomeNav() {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user); // Adjusted to match slice state
  // const { success } = useSelector((state) => state.login);
  const [isProfile, setIsProfile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  // Select avatar based on user's gender
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleBadge = () => {
    setIsVisible(!isVisible);
  };
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    setIsProfile(false);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
    toast.success("account removed successfully");
  };
  const token = localStorage.getItem("token");

  useEffect(() => {
    // Check if token is in localStorage
    const token = localStorage.getItem("token");
    if (token) {
      setIsProfile(true);
      const userId = localStorage.getItem("userId");
      if (userId) {
        dispatch(fetchUser(userId));
      }
    }
  }, [dispatch]); // Add dispatch to the dependency array
  const getUserInitials = (firstName, lastName) => {
    return `${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`.toUpperCase();
  };
  const initials = getUserInitials(userData?.lastName,userData?.firstName);

  return (
    <div
      style={{
        backgroundImage: `url(${bgimg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#00171F",
      }}
    >
      <nav className="home-nav">
        <div className="nav-logo">
          <img src={logo} alt="logo" />
        </div>
        <div className={`nav-links ${isOpen ? "open" : ""}`}>
          <a href="#home" className="active">
            Home
          </a>
          {token ? (
            <button style={{ color: "green" }}>
              <Link to="/user-dashboard" style={{ color: "green" }}>
                SubmitCase/DashBoard
              </Link>
            </button>
          ) : (
            <Link to="/login">SubmitCase</Link>
          )}

          <a href="#services">Consultance</a>
          <a href="#contact">Internship</a>
          <a href="#contact">Contacts</a>

          {isProfile && (
            <a
              href="#account"
              className={`account ${isVisible ? "openProfile" : ""}`}
            >
              <div className="img" onClick={toggleBadge}>{initials}</div>
              <div className={`float-badge ${isVisible ? "visible" : ""}`}>
                <div className="float-links">
                  <p>
                    Hello!{" "}
                    <span>
                      <b>{userData?.firstName}</b>
                    </span>
                  </p>
                  <hr />
                  <p className="my-account"><Link to="/profile" style={{color:"#021742",textDecoration:"none"}}>Profile</Link></p>
                  <hr />
                  <p className="logout" onClick={logout}>
                    Logout
                  </p>
                </div>
              </div>
            </a>
          )}
        </div>

        <div className="nav-toggle" onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </nav>
      <hr />
      <ToastContainer />
    </div>
  );
}

export default HomeNav;
