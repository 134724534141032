import React, { useState, useEffect,useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCase,getSingleCase } from "../../redux/slices/updateCase";
import { toast } from "react-toastify";
import HomeNav from "../homenav/HomeNav";
import { useParams} from "react-router-dom";
import "./update.scss";
import { useNavigate } from "react-router-dom";

const UpdateCase = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { caseData, loading, error } = useSelector((state) => state.updateCase);
  const { id: caseId } = useParams();

 

  // Fetch the single case when the component mounts
  useEffect(() => {
    if (caseId) {
      dispatch(getSingleCase(caseId));
    }
  }, [caseId, dispatch]);

  // Populate the form with the fetched case data
  useEffect(() => {
    if (caseData) {
      setFormData({
        caseType: caseData.caseType || "",
        caseSubtype: caseData.caseSubtype || "",
        summary: caseData.summary || "",
        file: null, // Keep file as null for re-upload
      });
    }
  }, [caseData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Directly capture form data from event.target
    const data = {
      caseType: formData.caseType,
      caseSubtype: formData.caseSubtype,
      summary: formData.summary,
      file: formData.file,
    };

    if (caseId) {
      dispatch(updateCase({ id: caseId, data }))
        .unwrap()
        .then(() => {
          toast.success("Case Updated Successfully!");

          setTimeout(() => {
            navigate("/user-dashboard");
          }, 1000);
        })
        .catch((err) => {
          const errorMessage = err.message || JSON.stringify(err);
          toast.error(`Error: ${errorMessage}`);
        });
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error}`);
    }
  }, [error]);
  const subjectObject = useMemo(
    () => ({
      "Criminal Cases": [
        "Rape",
        "Child defilement",
        "Torture",
        "Assault or battery",
        "Abortion",
        "Threats to harm a person",
        "Sexual harassment",
        "Theft",
        "Extortion",
        "Fraud and others",
      ],
      "Civil matters": [
        "Land",
        "Marriage",
        "Divorce",
        "Contract",
        "Succession",
        "Insurance",
        "Expropriation",
        "Labour and others",
      ],
      "Alternative Dispute Resolutions": [
        "Mediation",
        "Arbitration",
        "Conciliation",
      ],
    }),
    []
  );
  const [selectedSubject, setSelectedSubject] = useState("");
  const [topics, setTopics] = useState([]);
  const [formData, setFormData] = useState({
    caseType: "",
    caseSubtype: "",
    summary: "",
    file: null,
  });
  useEffect(() => {
    if (selectedSubject) {
      setTopics(subjectObject[selectedSubject] || []);
      setFormData({ ...formData, caseType: selectedSubject });
    }
  },[selectedSubject, subjectObject, formData]);

  return (
    <div>
      <HomeNav />
      <div className="update-container">
        <div className="update-case">
          <form onSubmit={handleSubmit}>
            <p>Update Case</p>
            <label>
              <span>Case type</span>
              <select
              id="subject"
              className="subject"
              value={formData.caseType}
              onChange={(e) => setSelectedSubject(e.target.value)}
            >
              <option value="">Select subject first</option>
              {Object.keys(subjectObject).map((subject) => (
                <option key={subject} value={subject}>
                  {subject}
                </option>
              ))}
            </select>
            </label>
            <label>
              <span>Case subtype</span>
              <select
              id="topic"
              name="caseSubtype"
              className="topic"
              value={formData.caseSubtype}
              onChange={handleInputChange}
            >
              <option value="">{formData.caseSubtype}</option>
              {topics.map((topic) => (
                <option key={topic} value={topic}>
                  {topic}
                </option>
              ))}
            </select>
            </label>
            <label>
              <span>File</span>
              <input
                id="file"
                name="file"
                type="file"
                onChange={handleFileChange}
              className="file"/>
            </label>
            <label>
              <span>Case Summary</span>
              <textarea
                name="summary"
                value={formData.summary}
                onChange={handleInputChange}
              ></textarea>
            </label>
            {loading ? (
              <button>Updating...</button>
            ) : (
              <button type="submit">Update Case</button>
            )}
          </form>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default UpdateCase;
