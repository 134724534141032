import home from "../../assets/images/home.svg";
import send from "../../assets/images/send.svg";
import { Link } from "react-router-dom";
import table from "../../assets/images/table.svg";
import message from "../../assets/images/message.svg";
import finalwhite from "../../assets/images/finalwhite.png";

const StaffNav = () => {
  return (
    <div className="staff-nav">
      <div>
        <p>Dashboard</p>
        <img src={table} className="home-staff" alt="Dashboard" />
       
      </div>
      <hr />
      <div>
        <img src={finalwhite} className="logo" alt="Logo" />
      </div>

      <div>
        <img src={home} className="home-staff" alt="Home" />
        <Link to={'/'} style={{textDecoration:"none", color:"white"}}>Home</Link>
      </div>
      <div>
        <img src={send} className="home-staff" alt="Submit Case" />
        <p>Submited Cases</p>
      </div>
      <div>
        <img src={message} className="home-staff" alt="Messages" />
        <p>Messages</p>
      </div>
    </div>
  );}

export default StaffNav
