import React, {  } from "react";
import "./dashboard.scss";
import DashboardNavBar from "./dashboardNavBar.jsx";
import SideNavBar from "./sideNavBar.jsx";
import SubmitedCases from "./cases.jsx";
function CaseDashboard() {
  return (
    <>
      <DashboardNavBar />
      <div className="dash-body">
        <SideNavBar />
      <SubmitedCases />
      </div>
      
    </>
  );
}

export default CaseDashboard;
