import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// Thunk to fetch user data

export const fetchUser = createAsyncThunk(
  "fetchUser/GetUserInfo",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.get(`${BACKEND_URL}/users/${id}`, {
        headers: {
          // Corrected to be an object
          Authorization: `Bearer ${token}`, // Added the Authorization key
        },
      });
      console.log(response.data.data,'from user logged in');
      return response.data.data; // Ensure this is serializable
    } catch (error) {
      console.error("Fetch Error:", error.response?.data || error.message);
      toast.error(error.response?.data?.message || "Failed to fetch user data");
      return rejectWithValue(
        error.response?.data || "Failed to fetch user data"
      );
    }
  }
);

// Initial state
const initialState = {
  userData: null, // Hold user data
  loading: false, // Loading status
  error: null, // Error message
};

// Slice to handle user data fetch
const fetchUserSlice = createSlice({
  name: "fetchUser",
  initialState,
  reducers: {
    clearState: (state) => {
      state.userData = null; // Hold user data
      state.loading = false; // Loading status
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.userData = action.payload; // Assign fetched data to userData
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.error = action.payload; // Assign error to state
        state.userData = null; // Reset userData on error
        state.loading = false;
      });
  },
});

// Export reducer for store
export default fetchUserSlice.reducer;
