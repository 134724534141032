import React from "react";

import home from "../../assets/images/home.svg";
import message from "../../assets/images/message.svg";
import send from "../../assets/images/send.svg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./profile.scss";
import { useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchUser } from "../../redux/slices/userSlice";

const Profile = ({ onToggleSubmitCase }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userData } = useSelector((state) => state.user);
  const [isVisible, setIsVisible] = useState(false);
  // Helper function to get user initials
  const getUserInitials = (firstName, lastName) => {
    return `${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`.toUpperCase();
  };
  const toggleBadge = () => {
    setIsVisible(!isVisible);
  };
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    // setIsProfile(false);
    navigate("/");
    setTimeout(() => {
      window.location.reload();
    }, 2000);
    toast.success("account removed successfully");
  };

  useEffect(() => {
    // Check if token is in localStorage
    const token = localStorage.getItem("token");
    if (token) {
      // setIsProfile(true);
      const userId = localStorage.getItem("userId");
      if (userId) {
        dispatch(fetchUser(userId));
      }
    }
  }, [dispatch]);
  const initials = getUserInitials(userData?.lastName,userData?.firstName);
  return (
    <div className="profile-container">
      <div className="sidebar-menu">
        <img src={home} className="home" alt="Home" />
        <img src={message} className="home" alt="Messages" />
        <div onClick={onToggleSubmitCase} className="submit">
          <img src={send} className="home" alt="Submit Case" />
          <p>
            Submit <br /> Case
          </p>
        </div>
        {/* <img src={menu} alt="Menu icon" onClick={toggleNav} />
        {isNavOpen && <Nav onClose={toggleNav} isNavOpen={isNavOpen}/>} */}
      </div>
      <div className="profile" >
      
      <div className="img" onClick={toggleBadge}>{initials}</div>
        {/* <img src={male} alt="User profile" onClick={toggleBadge} /> */}
        <div className={`float-badge ${isVisible ? "visible" : ""}`}>
          <div className="float-links">
            <p>
              Hello!{" "}
              <span>
                <b>{userData?.lastName ||userData?.firstName }</b>
              </span>
            </p>
            <hr />
            <p className="my-account"><Link to="/profile">Profile</Link></p>
            <hr />
            <p className="logout" onClick={logout}>
              Logout
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
