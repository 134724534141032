import React, { useEffect, useState } from "react";
import "./action.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  assigning,
  getLawyers,
  markAsDone,
  status,
} from "../../redux/slices/caseActions";
import { getSingleCase } from "../../redux/slices/singleCaseSlice";
import { ToastContainer } from "react-toastify";

function Actions({ caseId }) {
  const dispatch = useDispatch();
  const { loading, lawyers } = useSelector((state) => state.done);
  const { singleCaseData } = useSelector((state) => state.singleCase);
  console.log("retrieved lawyers", lawyers);

  const [markAsDoneValue, setMarkAsDoneValue] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [assigningCaseValue, setAssigningCaseValue] = useState("");

  useEffect(() => {
    if (caseId) {
      dispatch(getSingleCase(caseId));
      dispatch(getLawyers());
    }
  }, [caseId, dispatch]); // Add caseId as a dependency

  useEffect(() => {
    if (singleCaseData) {
      setMarkAsDoneValue(singleCaseData.markAsDoneArchived || "");
      setStatusValue(singleCaseData.status || "");
      setAssigningCaseValue(singleCaseData.assignedTo || "");
    }
  }, [singleCaseData]);

  const handleMarkAsDoneChange = (e) => setMarkAsDoneValue(e.target.value);
  const handleStatusChange = (e) => setStatusValue(e.target.value);
  const handleAssigningCaseChange = (e) =>
    setAssigningCaseValue(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (caseId && markAsDoneValue && statusValue && assigningCaseValue) {
      dispatch(
        markAsDone({
          id: caseId,
          value: { markAsDoneArchived: markAsDoneValue },
        })
      );
      dispatch(status({ id: caseId, value1: { status: statusValue } }));
      dispatch(
        assigning({ id: caseId, value3: { assignedTo: assigningCaseValue } })
      );
    } else {
      console.error("Case ID or other values are undefined");
    }
  };

  return (
    <div className="actions-body">
      <center>
        <h1>ACTIONS</h1>
      </center>
      <form className="action-form" onSubmit={handleSubmit}>
        <div className="done">
          Mark As Done/Archived:{" "}
          <label>
            <select
              id="markAsDone"
              name="markAsDone"
              className="topic"
              value={markAsDoneValue}
              onChange={handleMarkAsDoneChange}
            >
              <option value={singleCaseData?.markAsDoneArchived}>
                {singleCaseData?.markAsDoneArchived || "Select"}
              </option>
              <option value="Done">Done</option>
              <option value="Going">Going</option>
            </select>
          </label>
        </div>
        <div className="status">
          Change Status:{" "}
          <label>
            <select
              id="status"
              name="Status"
              className="topic"
              value={statusValue}
              onChange={handleStatusChange}
            >
              <option value={singleCaseData?.status}>
                {singleCaseData?.status || "Select"}
              </option>
              <option value="Received">Received</option>
              <option value="Pending">Pending</option>
              <option value="Completed">Completed</option>
            </select>
          </label>
        </div>
        <div className="assign">
          Assigning Case To:{" "}
          <label>
            <select
              id="assigningCase"
              name="AssigningCase"
              className="topic"
              value={assigningCaseValue}
              onChange={handleAssigningCaseChange}
            >
              {/* Null or placeholder option */}
              <option value="none">
                {singleCaseData?.assignedTo
                  ? "Change Assignment"
                  : "Select a lawyer"}
              </option>
              {lawyers?.data &&
                lawyers.data.map((lawyer, index) => (
                  <option key={index} value={lawyer.email}>
                    {lawyer.email}
                  </option>
                ))}
            </select>
          </label>
        </div>
        {loading ? (
          <button>Saving Changes....</button>
        ) : (
          <button type="submit" disabled={loading}>
            Save Changes
          </button>
        )}
      </form>
      <ToastContainer />
    </div>
  );
}

export default Actions;
