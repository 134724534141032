import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';

// Backend URL from environment variables
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const resetPassword = createAsyncThunk(
  'reset/resetPassword',
  async (userData, { rejectWithValue }) => {
    try {
      // Send the userData directly as the payload
      const response = await axios.post(`${BACKEND_URL}/users/reset`, userData);
      console.log('ressetting data:',response.data);
      return response.data;
    } catch (error) {
      console.log("Error in resetPassword:", error.response?.data.message || error.message);

      // Reject the thunk with a custom error message
      return rejectWithValue(error.response?.data || 'Failed to reset password');
    }
  }
);

const initialState = {
  user: null,
  loading: false,
  error: null
};

const resetSlice = createSlice({
  name: 'reset',
  initialState,
  reducers: {
    // clearState: (state) => {
    //   state.user = null;
    //   state.loading = false;
    //   state.error = null;
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPassword.pending, (state) => {
        state.user = null;
        state.loading = true;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.user = null;
        state.loading = false;
        state.error = action.payload || 'Failed to reset password';
      });
  }
});

// Exporting the action to clear state
// export const { clearState } = resetSlice.actions;

export default resetSlice.reducer;
