
import "./staffNav.scss";
import Profile from "../userDashboard/Profile";
import StaffNav from "./StaffNav";
import Content from "./StaffContent";

const StaffDash = () => {
  return (
    <div>
      <div className="dashbord-section">
        <StaffNav />
        <div className="dash-content">
          <Profile />
          <div className="dash-cases">
            <Content></Content>
            {/* <hr style={{width:"1100px",color:" hsla(225, 80%, 92%, 0.53)",marginTop:"10px"}}/> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffDash;
