import React from "react";
import "./team.scss";
import emmy from "../../assets/images/emmy.png";
import paccy from "../../assets/images/team.png";
import hubert from "../../assets/images/hubert.png";
import yves from "../../assets/images/yves.png";

const AllTeam = () => {
  const bgImage = require("../../assets/images/bg.png").default;
  return (
    <div className="boardTeam">
      <div className="heading">
        <center>
          <h1>Meet The Team</h1>
        </center>
        <hr style={{backgroundColor:"#021742"}}/>
      </div>

      <div className="team">
        <div id="team-container" className="hubert">
          <div
            className="profile-img"
            style={{
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundColor: " #021742",
            }}
          >
            <img src={hubert} alt="huert" />
          </div>
          <h3>
            HUBERT <br />
            TWIZERE
          </h3>
          <div className="description">
            <p>
              LLB candidate with Background in Midwifery, SRHR Specialist with
              GBV Prevention Skills. He worked as RSSB intern Pharmaceutical
              Division and Now working at Kacyiru Hospital in Gynecology and
              Obstetrics Department .
            </p>
          </div>
          <footer
            style={{
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundColor: " #021742",
            }}
          >
            <div className="likes">
              <h4>CEO & FOUNDER</h4>
            </div>

            
          </footer>
        </div>
        <br />
        <div id="team-container">
          <div
            className="profile-img"
            style={{
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundColor: " #021742",
            }}
          >
            <img src={paccy} alt="paccy" />
          </div>
          <h3>
            PACIFIQUE <br />
            MBONIMANA
          </h3>
          <div className="description">
            <p>
              Enthusiastic and dedicated Software Developer with a strong
              foundation in computer science and programming principles.
            </p>
          </div>
          
          <footer
            style={{
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundColor: " #021742",
            }}
          >
            <div className="likes">
              <h4>COO & CO-FOUNDER</h4>
            </div>
          </footer>
        </div>
      </div>
      {/* --------------------------------------------------------- */}
      <div className="team">
        <div id="team-container">
          <div
            className="profile-img"
            style={{
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundColor: " #021742",
            }}
          >
            <img src={yves} alt="yves" />
          </div>
          <h3>
            YVES <br />
            TUGIRAMAHORO
          </h3>
          <div className="description">
            <p>
              Chief Legal Officer at Lachs Golden Ltd, LL.B from Kigali
              Independent University, pursuing LL.M, Executive Director at
              Choice for Hope Initiative. Passionate about social justice.
            </p>
          </div>
          <footer
            style={{
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundColor: " #021742",
            }}
          >
            <div className="likes">
              <h4>HRM & CO-FOUNDER</h4>
            </div>

            <div className="projects"></div>
          </footer>
        </div>
        <br />
        <div id="team-container">
          <div
            className="profile-img"
            style={{
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundColor: " #021742",
            }}
          >
            <img src={emmy} alt="emmy" />
          </div>
          <h3>
            EMMANUEL <br />
            NZAYISENGA
          </h3>
          <div className="description">
            <p>
              Student at the University of Rwanda in computer science and a
              software developer proficient in multiple programming languages.
            </p>
          </div>
          
          <footer
            style={{
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundColor: " #021742",
            }}
          >
            <div className="likes">
              <h4>CTO & CO-FOUNDER</h4>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default AllTeam;
