import React from 'react';
import './modalStyles.scss';

const SingleCaseModal = ({ children, onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div onClick={onClose} className="modal-close-button">
          &times;
        </div>
        {children}
      </div>
    </div>
  );
};

export default SingleCaseModal;
