import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const addCase = createAsyncThunk(
  "addCase/SubmitCase",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BACKEND_URL}/cases/register`,
        formData, // Pass FormData directly here
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data", // Ensure multipart/form-data
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Send Error:", error.response?.data || error.message);
      toast.error(
        error.response?.data?.message || "Failed to submit case data"
      );
      return rejectWithValue(
        error.response?.data || "Failed to submit case data"
      );
    }
  }
);

const initialState = {
  casesData: null,
  loading: false,
  error: null,
  addsuccess: false,
};

const addCaseSlice = createSlice({
  name: "addCase",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addCase.pending, (state) => {
      state.casesData = null;
      state.loading = true;
      state.error = null;
      state.addsuccess = false;
    })
    .addCase(addCase.fulfilled, (state,action)=>{
      state.casesData= action.payload;
      state.loading=false;
      state.addsuccess=true;
      state.error=null
    })
    .addCase(addCase.rejected, (state,action)=>{
      state.error= action.payload;
      state.loading= false
    })
  },
});
export default addCaseSlice.reducer;
