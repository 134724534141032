import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import Welcome from './components/welcome/welcome';

import UserDash from './components/userDashboard/UserDash'
import SignupComponent from './components/signup/SignupComponent'
import Login from './components/Login/login';
// import Services from './components/services/services';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import EmailTemplate from './components/Emailtemperate/EmailTemperate';
import EmailSent from './components/EmailSent/EmailSent'
import StaffDash from './components/StaffAdmin/StaffDash';
import Verify from './components/verify/Verify';
import ResetPasswordForm from './components/ForgotPassword/resetpasswordForm'
import SingleCase from './components/SingleCase/SingleCase';
import UpdateCase from './components/Updatecase/UpdateCase';
import CaseDashboard from './components/adminDashboard/casesPage';
import Dashboard from './components/adminDashboard/dashboard';
import UsersPage from './components/adminDashboard/usersPage';
import Profile from './components/Profile/Profile';
function App() {
  return (
    <Router>
    <Routes>
      {/* <Route path="/" element={<CaseDashboard />} /> */}
      <Route path="/" element={<Welcome />} />

      
      <Route path="/login" element={<Login />} />
      <Route path="/user-dashboard" element={<UserDash />} />
      <Route path="/staff-dashboard" element={<StaffDash />} />

      <Route path="/register" element={<SignupComponent />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/verify" element={<EmailTemplate />} />
      <Route path="/verifyno1" element={<EmailSent />} />
      <Route path="/verifyno" element={<Verify />} />
      <Route path="/reset-password" element={<ResetPasswordForm />} />
      <Route path="/single-case" element={<SingleCase />} />
      <Route path="/update-case/:id" element={<UpdateCase />} />
      <Route path="/admin-dashboard" element={<Dashboard />} />

      <Route path="/submitted-cases" element={<CaseDashboard />} />
      <Route path="/users" element={<UsersPage />} />
      <Route path="/profile" element={<Profile />} />


    </Routes>

  </Router>
  );
}

export default App;
