import React, { useState, useEffect } from "react";
import "./profile.scss";
import HomeNav from "../homenav/HomeNav";
import { updateUser } from "../../redux/slices/profile";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { fetchUser } from "../../redux/slices/userSlice";
import 'react-toastify/dist/ReactToastify.css';
import { url } from "./url";

const Profile = () => {
  const dispatch = useDispatch();
  const { userData}=useSelector((state)=>state.user)
  const { loading, success, error } = useSelector((state) => state.updateUser);

  // State to manage form data
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    idNumber: "",
    phoneNumber:"",
    district: "",
    sector: "",
    cell: "",
    password: "",
  });
  const userId=localStorage.getItem("userId")
  console.log(userId,"from fectibnnbn")
  useEffect(() => {
    dispatch(fetchUser(userId));
  }, [dispatch, userId]);
  useEffect(() => {
    if (userData) {
      setFormData({
        firstName: userData.firstName || "",
        lastName: userData.lastName || "",
        email: userData.email || "",
        idNo: userData.idNo || "",
        phoneNumber: userData.phoneNumber || "",
        district: userData.district || "",
        sector: userData.sector || "",
        cell: userData.cell || "",
        password: "", // Keep password empty for security reasons
      });
    }
  }, [userData]);
  
  // Handler to update form state when inputs change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
const data={
  firstName:formData.firstName,
  lastName:formData.lastName,
  idNo:formData.idNo,
  phoneNumber:formData.phoneNumber,
  district:formData.district,
  sector:formData.sector,
  cell:formData.cell,
}
  // Handler for form submission
  
  
  console.log(userId,"id from llllll===================")
  const handleSubmit = (e) => {
    e.preventDefault();
    // Dispatch the updateUser action with the form data
 
    dispatch(updateUser({userId,data}));
  };

  // Display toast notification on success or error
  useEffect(() => {
    if (success) {
      toast.success("Profile updated successfully!");
    } else if (error) {
      toast.error("Failed to update profile. Please try again.");
    }
  }, [success, error]);

  return (
    <div>
      <HomeNav />
      <div className="main">
        <div className="profile-content">
          <div className="profile-image">
            <p>Update Profile</p>
            <img src={url} alt="Profile" />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="profile-data">
              <div className="names">
                <label>
                  <span>First name:</span>
                  <input
                    type="text"
                    placeholder="First name"
                    value={formData.firstName}
                    name="firstName"
                    onChange={handleChange}
                  />
                </label>
                <label>
                  <span>Last Name:</span>
                  <input
                    type="text"
                    placeholder="Last name"
                    value={formData.lastName}
                    name="lastName"
                    onChange={handleChange}
                  />
                </label>
              </div>
              <div className="profile-input">
                <label>
                  <span>Email:</span>
                 <p>{formData.email}</p>
                </label>
              </div>
              <div className="numbers">
                <label>
                  <span>Id/Tin/Passport number:</span>
                  <input
                    type="text"
                    placeholder="ID/TIN/Passport number"
                    value={formData.idNo}
                    name="idNumber"
                    onChange={handleChange}
                  />
                </label>
                <label>
                  <span>Phone Number:</span>
                  <input
                    type="text"
                    placeholder="Phone number"
                    value={formData.phoneNumber}
                    name="phoneNumber"
                    onChange={handleChange}
                  />
                </label>
              </div>

              <div className="sectors">
                <label>
                  <span>District:</span>
                  <input
                    type="text"
                    placeholder="District"
                    value={formData.district}
                    name="district"
                    onChange={handleChange}
                  />
                </label>
                <label>
                  <span>Sector:</span>
                  <input
                    type="text"
                    placeholder="Sector"
                    value={formData.sector}
                    name="sector"
                    onChange={handleChange}
                  />
                </label>
                <label>
                  <span>Cell:</span>
                  <input
                    type="text"
                    placeholder="Cell"
                    value={formData.cell}
                    name="cell"
                    onChange={handleChange}
                  />
                </label>
              </div>
              <div className="password-field">
                <label>
                  <span>Password:</span>
                <p>******</p>
                </label>
                <button type="button" onClick={() => alert("Update Password")}>
                  Update Password
                </button>
              </div>
              <div>
                <button className="all" type="submit" disabled={loading}>
                  {loading ? "Updating..." : "Update"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <ProfileFooter /> */}
      <ToastContainer />
    </div>
  );
};

export default Profile;
