import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, Link } from "react-router-dom";
import queryString from "query-string";
import "./email.scss";
import { toast, ToastContainer } from "react-toastify";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const EmailTemplate = () => {
  const location = useLocation();
  // const [status, setStatus] = useState("Verifying...");
  const [isVerifyVisible, setIsVerifyVisible] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    // Extract token from query parameters
    const { token } = queryString.parse(location.search);
    setToken(token);
  }, [location.search]);

  useEffect(() => {
    if (!token) return; // Exit if token is not available

    const verifyToken = async () => {
      try {
        // console.log("Verifying token:", token); // Debug token value
        const response = await axios.post(`${BACKEND_URL}/users/verify/${token}`, { token });
        // console.log(response.data.message);

        if (response.data.status === "success") {
          
          setIsVerifyVisible(true);
          toast.success("Verification successful");
        } else {
          // setStatus("Invalid or expired token.");
          toast.error("Invalid or expired token.");
        }
      } catch (error) {
        console.error("Error verifying token:", error);
      
        toast.error("An error occurred while verifying your email.");
      }
    };

    verifyToken();
  }, [token]);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={`email-content ${isVerifyVisible ? "isVisible" : ""}`}>
        
        {isVerifyVisible && (
          <div style={{ textAlign: "center" }} className="login">
            <Link to="/login" className="verify-login">
              Login
            </Link>
          </div>
        )}
      </div>
      <ToastContainer/>
    </div>
  );
};

export default EmailTemplate;
