import React, { useState, useEffect } from "react";
import "./dash.scss";
import Content from "./Content";
import Nav from "./Nav";
import Profile from "./Profile";
import SubmitCase from "./SubmitCase";
import ProfileDetail from "./ProfileDetail";
import { ToastContainer } from "react-toastify";
import { useSelector} from "react-redux";

const UserDash = () => {
  const { addsuccess } = useSelector((state) => state.addCase);

  const [isSubmitCaseOpen, setIsSubmitCaseOpen] = useState(false);
  const [isProfileDetailOpen, setIsProfileDetailOpen] = useState(false);
 
  const toggleSubmitCase = () => {
    setIsSubmitCaseOpen(!isSubmitCaseOpen);
    setIsProfileDetailOpen(false); // Close the other modal if it's open
  };

  const toggleProfileDetail = () => {
    setIsProfileDetailOpen(!isProfileDetailOpen);
    setIsSubmitCaseOpen(false); // Close the other modal if it's open
  };
  useEffect(() => {
    if (addsuccess) {
      window.location.reload();

    }
  }, [addsuccess]);
  

  return (
    <div>
      <div className="dashbord-section">
        <Nav onToggleSubmitCase={toggleSubmitCase} />
        <div className="dash-content">
          <Profile onToggleProfileDetail={toggleProfileDetail } onToggleSubmitCase={toggleSubmitCase}/>


          {isSubmitCaseOpen && (
            <SubmitCase onClose={toggleSubmitCase} />
          )}
          {isProfileDetailOpen && (
            <ProfileDetail onClose={toggleProfileDetail} />
          )}
          <div className="dash-cases">
            <Content onToggleSubmitCase={toggleSubmitCase}/>
          </div>
          {/* <div className="footer">Copy & write 100 Hills Solicitors</div> */}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default UserDash;
