import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// Thunk for fetching cases
export const fetchCases = createAsyncThunk(
  'getCases/fetchCases',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error("No token found");
        return;
      }
      console.log("token is here:",token);
      const response = await axios.get(`${BACKEND_URL}/cases/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data.data);
      return response.data.data;
    } catch (error) {
      console.error("Fetch Error:", error.response?.data || error.message);
      return rejectWithValue(error.response?.data || 'Failed to fetch case data');
    }
  }
);

// Thunk for deleting a case
export const deleteCase = createAsyncThunk(
  'cases/deleteCase',
  async (caseId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`${BACKEND_URL}/cases/${caseId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

   
console.log(response.data)
      return response.data;
    } catch (error) {
      console.error("Delete Error:", error.response?.data || error.message);
      return rejectWithValue(error.response?.data || 'Failed to delete case');
    }
  }
);

// Initial state
const initialState = {
  cases: [],
  loading: false,
  error: null,
};

// Create slice
const fetchCasesSlice = createSlice({
  name: 'fetchCases',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch cases reducers
      .addCase(fetchCases.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCases.fulfilled, (state, action) => {
        state.cases = action.payload || [];
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchCases.rejected, (state, action) => {
        state.error = action.payload;
        state.cases = [];
        state.loading = false;
      })
      // Delete case reducers
      .addCase(deleteCase.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCase.fulfilled, (state, action) => {
        state.cases = state.cases.filter(caseItem => caseItem.id !== action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteCase.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  }
});

export default fetchCasesSlice.reducer;

