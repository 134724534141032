import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const changePassword = createAsyncThunk(
  'changePassword/changePassword',
  async ({ token, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BACKEND_URL}/users/reset/${token}`, { password });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to change password');
    }
  }
);

const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState: {
    loading: false,
    error: null,
    success: null,
  },
  reducers: {
    // clearState: (state) => {
    //   state.loading = false;
    //   state.error = null;
    //   state.success = null;
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { clearState } = changePasswordSlice.actions;
export default changePasswordSlice.reducer;
