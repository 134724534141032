import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const login = createAsyncThunk(
  "login/loginUser",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BACKEND_URL}/users/login`, userData);
      console.log(response.data.token);
      toast.success('Login Successfully')
      localStorage.setItem("token", response.data.token)
      localStorage.setItem("userId",response.data.user.userId)
      localStorage.setItem("email",response.data.user.email)
      return response.data;
    } catch (error) {
      console.error("Login Error:", error.response?.data || error.message);
      toast.error( error.response?.data.message );

      return rejectWithValue(error.response?.data || 'Failed to login');
    }
  }
);
const initialState = {
  user: null,
  loading: false,
  error: null,
  success: false,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    clearState: (state) => {
      state.user = null;
      state.loading = false;
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: (buider) => {
    buider
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to login";
      });
  },
});
export const { clearState } = loginSlice.actions;
export default loginSlice.reducer;
