import React, {  } from "react";
import  './dashboardNavBar.scss'
import notification from '../../assets/images/notification.png'
function DashboardNavBar() {
  

  return (
    <>
    <div className="admin-nav"> 
      <div className="left-side">
        ADMIN DASHBOARD
      </div>
      <div className="right-side">
        <div className="notification"><img src={notification} alt="" /></div>
        <div className="admin-profile">
          AD
        </div>
      </div>
    </div>
    </>
  );
}

export default DashboardNavBar;
