import React from "react";
import "./dashNav.scss";
import home from "../../assets/images/home.svg";
import send from "../../assets/images/send.svg";
import table from "../../assets/images/table.svg";
import message from "../../assets/images/message.svg";
import finalwhite from "../../assets/images/final.png";
import { Link } from "react-router-dom";

const Nav = ({ onToggleSubmitCase, isNavOpen }) => {
  
  return (
    <div className={`dash-nav ${isNavOpen ? "open" : ""}`}>
      <div>
        <p>Dashboard</p>
        <img src={table} className="home" alt="Dashboard" />
       
      </div>
      <hr />
      <div>
        <img src={finalwhite} className="logo" alt="Logo" />
      </div>

      <div>
        <Link to={'/'} className="link-to-home">
        <img src={home} className="home" alt="Home" />
        <p>Home</p>
        </Link>
        
      </div>
      <div onClick={onToggleSubmitCase}>
        <img src={send} className="home" alt="Submit Case" />
        <p>Submit Case</p>
      </div>
      <div>
        <img src={message} className="home" alt="Messages" />
        <p>Messages</p>
      </div>
    </div>
  );
};

export default Nav;
