import React, { useState, useEffect } from "react";
import './reset.scss';
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../redux/slices/changePassword";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Oval } from "react-loader-spinner";
import HomeNav from "../homenav/HomeNav";

const ResetPasswordForm = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.changePassword);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [token, setToken] = useState(""); // State to store the token

  // Extract the token from the URL when the component mounts
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = queryParams.get("token");
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    } else {
      toast.error("Invalid or missing token.");
    }
  }, []);

  const isStrongPassword = (password) => {
    // Check for a strong password (e.g., at least 8 characters, including uppercase, lowercase, number, and special character)
    const strongPasswordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return strongPasswordRegex.test(password);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isStrongPassword(password)) {
      toast.error("Password must be at least 8 characters long, including uppercase, lowercase, number, and special character.");
      return;
    } else {
      setPasswordError("");
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    } else {
      setConfirmPasswordError("");
    }

    if (!token) {
      toast.error("No valid token found.");
      return;
    }

    dispatch(changePassword({ token, password })).then((response) => {
      if (response.meta.requestStatus === "fulfilled") {
        toast.success("Password reset successfully!");
      } else {
        toast.error(error || "Failed to reset password. Please try again.");
      }
    });
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  return (
    <div className='reset-container-new'>
      <HomeNav />
      
      <div className="login-detail">
        <form id="forget-pass-form" onSubmit={handleSubmit}>
          <center><h3>Enter New Password</h3></center>
          <div className="input-field">
            <input
              type="password"
              placeholder="Enter Password*"
              className="email"
              name="password"
              value={password}
              onChange={handlePasswordChange}
              required
              disabled={loading} // Disable the input while loading
            />
            {passwordError && <p className="error">{passwordError}</p>}
            <input
              type="password"
              placeholder="Re-Enter Your Password*"
              className="email"
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
              disabled={loading} // Disable the input while loading
              onPaste={(e) => e.preventDefault()} // Prevent pasting
            />
            {confirmPasswordError && <p className="error">{confirmPasswordError}</p>}
            <button className="submit-button" type="submit" disabled={loading}>
              {loading ? (
                <span>
                  Updating...{" "}
                  <Oval
                    height={20}
                    width={20}
                    color="white"
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="darkblue"
                    strokeWidth={2}
                  />
                </span>
              ) : "Change Password"}
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ResetPasswordForm;
