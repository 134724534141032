import React, { useState, useEffect, useMemo } from "react";
import { IoMdClose } from "react-icons/io";
import "./submitcase.scss";
import { useDispatch, useSelector } from "react-redux";
import { addCase } from "../../redux/slices/addCaseSlice";
import { toast } from "react-toastify";

const SubmitCase = ({ onClose }) => {
  const dispatch = useDispatch();
  const { addsuccess, loading } = useSelector((state) => state.addCase);

  const subjectObject = useMemo(
    () => ({
      "Criminal Cases": [
        "Rape",
        "Child defilement",
        "Torture",
        "Assault or battery",
        "Abortion",
        "Threats to harm a person",
        "Sexual harassment",
        "Theft",
        "Extortion",
        "Fraud and others",
      ],
      "Civil matters": [
        "Land",
        "Marriage",
        "Divorce",
        "Contract",
        "Succession",
        "Insurance",
        "Expropriation",
        "Labour and others",
      ],
      "Alternative Dispute Resolutions": [
        "Mediation",
        "Arbitration",
        "Conciliation",
      ],
    }),
    []
  );

  const [selectedSubject, setSelectedSubject] = useState("");
  const [topics, setTopics] = useState([]);
  const [formData, setFormData] = useState({
    caseType: "",
    caseSubtype: "",
    summary: "",
    file: null,
  });

  useEffect(() => {
    if (selectedSubject) {
      setTopics(subjectObject[selectedSubject] || []);
      setFormData({ ...formData, caseType: selectedSubject });
    }
  }, [selectedSubject, subjectObject, formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic validation
    if (!formData.caseType) {
        toast.error("Please select a case type.");
        return;
    }
    if (!formData.caseSubtype) {
        toast.error("Please select a case subtype.");
        return;
    }
    if (!formData.summary.trim()) {
        toast.error("Please provide a case summary.");
        return;
    }
    if (!formData.file) {
        toast.error("Please upload a file.");
        return;
    }

    // Create a new FormData object
    const formSubmission = new FormData();
    formSubmission.append("caseType", formData.caseType);
    formSubmission.append("caseSubtype", formData.caseSubtype);
    formSubmission.append("summary", formData.summary);
    formSubmission.append("file", formData.file);

    // Dispatch the form data
    dispatch(addCase(formSubmission));
  };
  useEffect(() => {
    if (addsuccess) {

      // toast.success("Case Submitted Successfully!")
      setTimeout(() => {
      onClose();
        
      }, (2000));
    }
  }, [addsuccess, onClose]);

  return (
    <div className="submit-container">
      <div className="submit-case">
        <form onSubmit={handleSubmit}>
          <p className="close">
            <IoMdClose onClick={onClose} />
          </p>
          <p>Submit Case</p>
          <label>
            <span>Case type</span>
            <select
              id="subject"
              className="subject"
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
            >
              <option value="">Select subject first</option>
              {Object.keys(subjectObject).map((subject) => (
                <option key={subject} value={subject}>
                  {subject}
                </option>
              ))}
            </select>
          </label>
          <label>
            <span>Case subtype</span>
            <select
              id="topic"
              name="caseSubtype"
              className="topic"
              value={formData.caseSubtype}
              onChange={handleInputChange}
            >
              <option value="">Select case subtype</option>
              {topics.map((topic) => (
                <option key={topic} value={topic}>
                  {topic}
                </option>
              ))}
            </select>
          </label>
          <label>
            <span>File</span>
            <input type="file" onChange={handleFileChange} />
          </label>
          <label>
            <span>Case Summary</span>
            <textarea
              name="summary"
              value={formData.summary}
              onChange={handleInputChange}
            ></textarea>
          </label>
          {loading ? (
           
              <button>Submitting.......</button>
              
           
          ) : (
            <button type="submit">Submit</button>
          )}
        </form>
      </div>
    </div>
   
  );
};

export default SubmitCase;
