import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomToast from "../../components/customToast/toast";

// Backend URL from environment variables
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const register = createAsyncThunk(
  'register/registerUser',
  async (userData, { rejectWithValue }) => {
    try {
      console.log('backend url', BACKEND_URL)
      const response = await axios.post(`${BACKEND_URL}/users/register`, userData);
      console.log('added data', response.data)
      toast.success(<CustomToast message={"you have successfully registered, kindly check your email for verification"} type="success" />, {
        className: "custom-toast-bottom-right",  // Apply custom class for position
        autoClose: 5000,
        hideProgressBar: false,
      });
      return response.data;
    } catch (error) {
      console.log(error.response.data.message)
      // toast.error(error.response.data.message)
      toast.error(<CustomToast message={error.response.data.message} type="error" />, {
        className: "custom-toast-bottom-right",  // Apply custom class for position
        autoClose: 5000,
        hideProgressBar: false,
      });
      if (error.response && error.response.data) {
        // If the error response has data, pass it to rejectWithValue
        return rejectWithValue(error.response.data);
      } else {
        // If there is no error response, pass the error message
        return rejectWithValue(error.message);
      }
    }
  }
);
const initialState = {
  user: null,
  loading: false,
  error: null,
  successMessage: null,
};

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    clearState: (state) => {
      state.user = null;
      state.loading = false;
      state.error = null;
      state.successMessage = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.data;
        state.successMessage = action.payload.message;
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to register user';
      });
  }
});

export const { clearState } = registerSlice.actions;

export default registerSlice.reducer;