import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import eye from "../../assets/images/eye.png";
import bg from "../../assets/images/law.jpg";
import Pagination from "../pagination/pagination";
import { assignedCase } from "../../redux/slices/assignedCase";
import AdminSingleCaseModal from "../adminSinglemodal/adiminSingleCase";

import add from "../../assets/images/add.png"
import StaffSingleCase from "./SingleCase";

const Content = ({ onToggleSubmitCase }) => {
  const dispatch = useDispatch();
  const { cases } = useSelector((state) => state.assignedCase);
  const [currentPage, setCurrentPage] = useState(1);
  const casesPerPage = 3;

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const email = localStorage.getItem("email");
      if (email) {
        dispatch(assignedCase(email));
      }
    }
  }, [dispatch]);

  const totalPages = Math.ceil((cases?.length || 0) / casesPerPage);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };


  const indexOfLastCase = currentPage * casesPerPage;
  const indexOfFirstCase = indexOfLastCase - casesPerPage;
  const currentCases = cases?.slice(indexOfFirstCase, indexOfLastCase) || [];

  const truncateSummary = (summary, charLimit) => {
    return summary.length <= charLimit ? summary : summary.slice(0, charLimit) + "...";
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  const caseLength = cases.length;
const [isModalOpen,setIsModal]=useState(false);
const [caseId,setCaseId]=useState("");
console.log(caseId,"handler view===============")
const handlerView = (id) => {
  setIsModal(true);
  setCaseId(id);
};
const handlerCloseModel=()=>{
  setIsModal(false)
  setCaseId("")
}

  return (
    <div className="cases-table">
      <h3>Assigned Cases [{caseLength}]</h3>
      {caseLength === 0 ? (
        <div className="add-case">
          <h1>
            No Cases assigned to you{" "}
            <img src={add} alt="add case" onClick={onToggleSubmitCase} />{" "}
          </h1>
        </div>
      ) : (
        <>
          <div className="added-cases">
            {currentCases.map((caseItem, index) => (
              <div className="case-card" key={index}>
                <div
                  className="case-card-header"
                  style={{
                    backgroundImage: `url(${bg})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div className="view">
                  <img src={eye} alt="eye" onClick={() => handlerView(caseItem._id)} />

                    <p>View</p>
                  </div>
                </div>
                <div className="case-body">
                  <p>
                    <b>Email:</b> <span>{caseItem.caseOwner}</span>
                  </p>
                  <p>
                    <b>CaseType:</b> <span>{caseItem.caseType}</span>
                  </p>
                  <p>
                    <b>CaseSubType:</b> <span>{caseItem.caseSubtype}</span>
                  </p>
                  <p>
                    <b>Summary:</b>{" "}
                    <span>{truncateSummary(caseItem.summary, 50)}</span>
                  </p>
                  <p>
                    <b>File: </b>
                    <a
                      href={caseItem.file}
                      download
                      className="download-button"
                    >
                      Download
                    </a>
                  </p>
                  <p>
                    <b>Status:</b> <span>{caseItem.status}</span>
                  </p>
                  <p>
                    <b>CreatedAt:</b><span className="time">{formatDate(caseItem.createdAt)}</span>
                  </p>
               
                </div>
              </div>
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
         {isModalOpen && (
            <AdminSingleCaseModal onClose={handlerCloseModel}>
              <StaffSingleCase  caseId={caseId}/>
            </AdminSingleCaseModal>
          )}
        </>
      )}
    </div>
  );
};

export default Content;
