import React, { useState } from "react";
import "./cases.scss";
import Pagination from "../pagination/pagination";
import ActionsModal from "../actionsModal/actionsModal";
import Actions from "./actions";

const Users = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const casesPerPage = 10;
  const openModal = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
  };

  const casesData = [
    {
      id: "C001",
      owner: "John Doe",
      type: "Criminal",
      done: "Yes",
      assignedTo: "Lawyer A",
      status: "Pending",
    },
    {
      id: "C002",
      owner: "Jane Smith",
      type: "Civil",
      done: "No",
      assignedTo: "Lawyer B",
      status: "In Progress",
    },
    {
      id: "C003",
      owner: "Michael Brown",
      type: "Family",
      done: "Yes",
      assignedTo: "Lawyer C",
      status: "Completed",
    },
    {
      id: "C004",
      owner: "Emily White",
      type: "Immigration",
      done: "No",
      assignedTo: "Lawyer D",
      status: "Pending",
    },
    {
      id: "C005",
      owner: "William Black",
      type: "Corporate",
      done: "Yes",
      assignedTo: "Lawyer E",
      status: "In Progress",
    },
    {
      id: "C006",
      owner: "Olivia Green",
      type: "Real Estate",
      done: "No",
      assignedTo: "Lawyer F",
      status: "Pending",
    },
    {
      id: "C007",
      owner: "David Blue",
      type: "Tax",
      done: "Yes",
      assignedTo: "Lawyer G",
      status: "Completed",
    },
    {
      id: "C008",
      owner: "Sophia Yellow",
      type: "Environmental",
      done: "No",
      assignedTo: "Lawyer H",
      status: "In Progress",
    },
    {
      id: "C009",
      owner: "James Red",
      type: "Intellectual Property",
      done: "Yes",
      assignedTo: "Lawyer I",
      status: "Completed",
    },
    {
      id: "C010",
      owner: "Isabella Violet",
      type: "Employment",
      done: "No",
      assignedTo: "Lawyer J",
      status: "Pending",
    },
    {
      id: "C011",
      owner: "Ethan Gray",
      type: "Insurance",
      done: "Yes",
      assignedTo: "Lawyer K",
      status: "In Progress",
    },
    {
      id: "C012",
      owner: "Charlotte Brown",
      type: "Bankruptcy",
      done: "No",
      assignedTo: "Lawyer L",
      status: "Pending",
    },
    {
      id: "C013",
      owner: "Liam Black",
      type: "Criminal",
      done: "Yes",
      assignedTo: "Lawyer M",
      status: "Completed",
    },
    {
      id: "C014",
      owner: "Amelia White",
      type: "Family",
      done: "No",
      assignedTo: "Lawyer N",
      status: "In Progress",
    },
    {
      id: "C015",
      owner: "Mason Green",
      type: "Corporate",
      done: "Yes",
      assignedTo: "Lawyer O",
      status: "Pending",
    },
  ];

  const totalPages = Math.ceil(casesData.length / casesPerPage);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const indexOfLastCase = currentPage * casesPerPage;
  const indexOfFirstCase = indexOfLastCase - casesPerPage;
  const currentCases = casesData.slice(indexOfFirstCase, indexOfLastCase);

  return (
    <div className="cases-table-view">
      <table>
        <thead>
          <tr>
            <th>User Id</th>
            <th>Identifications</th>  {/* email and IdNo */}
            <th>Full Names</th>
            <th>Contacts</th>{/*  email and phone number*/}
            <th>Preferred contact</th>
            <th>Address</th>
            <th>Password</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentCases.map((caseItem) => (
            <tr key={caseItem.id}>
              <td>{caseItem.id}</td>
              <td>{caseItem.owner}</td>
              <td>{caseItem.type}</td>
              <td>{caseItem.done}</td>
              <td>{caseItem.assignedTo}</td>
              <td>{caseItem.status}</td>
              <td className="actions">
                <button>View</button>
                <button>Edit</button>
                <button>Delete</button>
                
              </td>
              <td>
              <button onClick={openModal}>Actions</button>
                {isModalVisible && (
                  <ActionsModal
                    onClose={closeModal}
                    children={<Actions/>}
                  ></ActionsModal>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default Users;
