import { configureStore } from '@reduxjs/toolkit';
import registerReducer from './slices/registerSlice';
import resetReducer from './slices/forgetPassSlice'; // Corrected
import changePassword from './slices/changePassword';
import loginSlice from './slices/loginSlice';
import fetchUser from './slices/userSlice';
import fetchCases from './slices/casesSlice';
import addCase from './slices/addCaseSlice';
import updateCaseSlice from './slices/updateCase';
import deleteCaseSlice from './slices/deleteCaseSlice';
import singleCaseSlice from './slices/singleCaseSlice';
import getAllCasesSlice from './slices/getAllCasesSlice';
import  markAsDoneSlice  from './slices/caseActions';
import assignedSlice  from './slices/assignedCase';
import updateUserSlice from './slices/profile';

const store = configureStore({
  reducer: {
    register: registerReducer,
    reset: resetReducer, // Corrected
    changePassword: changePassword,
    login: loginSlice,
    user: fetchUser,
    cases: fetchCases,
    addCase: addCase,

    updateCase: updateCaseSlice,
    assignedCase: assignedSlice,
    updateUser:updateUserSlice,

    deleteCase:deleteCaseSlice,
    singleCase:singleCaseSlice,
    allCases:getAllCasesSlice,
    done:markAsDoneSlice,
  }
});

export default store;
