import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const deleteCase = createAsyncThunk(
  "deletecase/deleteCase",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      
      
      const response = await axios.delete(`${BACKEND_URL}/cases/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Data from backend:", response.data);
      return response.data;
    } catch (error) {
      console.log("Send Error:", error.response?.data || error.message);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const initialState = {
  case: [],
  loading: false,
  error: null
};

const deleteCaseSlice = createSlice({
  name: 'deleteCase',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteCase.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCase.fulfilled, (state, action) => {
        state.loading = false;
        state.case = action.payload;
      })
      .addCase(deleteCase.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default deleteCaseSlice.reducer;
