import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const assignedCase = createAsyncThunk(
  "getCase/assignedCases",
  async (email, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${BACKEND_URL}/cases/assignedTo/${email}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("assignedData", response.data.data);
      return response.data.data;
    } catch (error) {
      console.error("Fetch Error:", error.response?.data || error.message);
      return rejectWithValue(error.response?.data || 'Failed to get assigned cases');
    }
  }
);

const initialState = {
  cases:[],
  loading:false,
  error:null,
};

export const assignedSlice = createSlice({
  name: "getAssignedCase",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(assignedCase.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(assignedCase.fulfilled, (state, action) => {
        state.cases = action.payload;
        
        state.loading = false;
        state.error = null;
      })
      .addCase(assignedCase.rejected, (state, action) => {
        state.error = action.payload;
        state.cases = [];
        state.loading = false;
      });
  },
});

export default assignedSlice.reducer;
