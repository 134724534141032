import React, { useState, useEffect } from "react";
import "./stats.scss";
import users from "../../assets/images/users.png";
import morningGreetings from "../../assets/images/mrng.png";
import noon from "../../assets/images/noon.png";
import eveningGreetings from "../../assets/images/evening.png";
import cases from '../../assets/images/submit.png'

function Stats() {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [greeting, setGreeting] = useState("");
  const [greetingImage, setGreetingImage] = useState(morningGreetings);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      setCurrentTime(now);

      const hour = now.getHours();
      if (hour < 12) {
        setGreeting("Good Morning Pacifique!");
        setGreetingImage(morningGreetings);
      } else if (hour < 18) {
        setGreeting("Good Afternoon Pacifique!");
        setGreetingImage(noon); // Change this to an afternoon image if available
      } else {
        setGreeting("Good Evening Pacifique!");
        setGreetingImage(eveningGreetings);
      }
    }, 1000);

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, []);

  return (
    <>
      <div className="admin-stats">
        <div className="greetings">
          <div className="greetings-card">
            <div className="name">{greeting}</div>
            <img src={greetingImage} alt="Greeting" />
          </div>
          <div className="profile-card">
            <div className="date-time">
              {currentTime.toLocaleDateString()} <br />
              {currentTime.toLocaleTimeString()}
            </div>
          </div>
        </div>
        <div className="right-cards">
          <div className="card">
            <div className="card-img">
              <img src={users} alt="" />
            </div>
            <div className="card-cont">
              <p>USERS:</p>
              <p>100</p>
            </div>
          </div>
          <div className="card">
            <div className="card-img">
              <img src={cases} alt="" />
            </div>
            <div className="card-cont">
              <p>SUBMITTEDCASES:</p>
              <p>100</p>
            </div>
          </div>
          <div className="card">
            <div className="card-img">
              <img src={users} alt="" />
            </div>
            <div className="card-cont">
              <p>MEASSAGES:</p>
              <p>100</p>
            </div>
          </div>
          <div className="card">
            <div className="card-img">
              <img src={users} alt="" />
            </div>
            <div className="card-cont">
              <p>USERS:</p>
              <p>100</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Stats;
