import React, { useEffect } from "react";
import "./cases.scss";
import { useDispatch, useSelector } from "react-redux";
import { getSingleCase } from "../../redux/slices/singleCaseSlice";
import download from '../../assets/images/download.png'

const AdminSingleCase = ({ caseId }) => {
  const dispatch = useDispatch();
  const { singleCaseData } = useSelector((state) => state.singleCase);

  useEffect(() => {
    if (caseId) {
      dispatch(getSingleCase(caseId));
    }
  }, [caseId, dispatch]);

  return (
    <div className="submitted-cases">
      <center><h1>Admin Single Case View</h1></center>
      <table>
        <thead>
          <tr>
            <th className="th">Case Id</th>
            <th className="th">Case Owner</th>
            <th className="th">Case Type</th>
            <th className="th">Case Sub Type</th>
            <th className="th">Case Summary</th>
            <th className="th">File</th>
          </tr>
        </thead>
        <tbody>
          {singleCaseData && Object.keys(singleCaseData).length > 0 ? (
            <tr>
              <td className="td">{singleCaseData._id}</td>
              <td className="td">{singleCaseData.caseOwner}</td>
              <td className="td">{singleCaseData.caseType}</td>
              <td className="td">{singleCaseData.caseSubtype}</td>
              <td className="td">{singleCaseData.summary}</td>
              <td className="td">
                <a href={`/${singleCaseData.file}`} download>
                <img src={download} alt="" style={{width:"50px"}} />
                  Download
                </a>
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan="6">No case data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AdminSingleCase;
