// CustomToast.js
import React from 'react';
import './toast.css'; // Custom CSS for toast
import toastbg from '../../assets/images/bg.png'

const CustomToast = ({  message, type }) => {
  return (
    <div className={`custom-toast ${type}`}style={{backgroundImage:{toastbg}}}>
      {message}
    </div>
  );
};

export default CustomToast;
