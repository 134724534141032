import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import CustomToast from "../customToast/toast";
import { useSelector, useDispatch } from "react-redux";
import "./signup.css";
import backGroundImage from "../../assets/images/bg.png";
import finalwhite from "../../assets/images/finalwhite.png";
import eye from "../../assets/images/eye.png";
import { Oval } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { register } from "../../redux/slices/registerSlice";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Signup = () => {
  const showPassRef = useRef(null);
  const passRef = useRef(null);
  const showConfirmRef = useRef(null);
  const confirmRef = useRef(null);

  useEffect(() => {
    const showPass = showPassRef.current;
    const pass = passRef.current;

    const togglePasswordVisibility = () => {
      pass.type = pass.type === "password" ? "text" : "password";
    };

    showPass.addEventListener("click", togglePasswordVisibility);
    return () => {
      showPass.removeEventListener("click", togglePasswordVisibility);
    };
  }, []);

  useEffect(() => {
    const showConfirm = showConfirmRef.current;
    const confirm = confirmRef.current;

    const toggleConfirmVisibility = () => {
      confirm.type = confirm.type === "password" ? "text" : "password";
    };

    showConfirm.addEventListener("click", toggleConfirmVisibility);
    return () => {
      showConfirm.removeEventListener("click", toggleConfirmVisibility);
    };
  }, []);

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.register);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    idNo: "",
    phoneNumber: "",
    preferredContact: "",
    district: "",
    sector: "",
    cell: "",
    password: "",
    confirmPassword: "",
  });

  const [errorMessage, setErrorMessage] = useState("");

  const requiredFields = useMemo(
    () => [
      "firstName",
      "email",
      "idNo",
      "phoneNumber",
      "preferredContact",
      "district",
      "sector",
      "cell",
      "password",
    ],
    []
  );

  const giveData = (fieldName) => {
    const messages = {
      firstName: "First Name is required.",
      email: "Email is required.",
      idNo: "ID/Tin/Passport Number is required.",
      phoneNumber: "Phone Number is required.",
      preferredContact: "Preferred Contact is required.",
      district: "District/Akarere is required.",
      sector: "Sector/Umurenge is required.",
      cell: "Cell/Akagari is required.",
      password: "Password is required.",
      confirmPassword: "Passwords do not match.",
      passwordStrength:
        "Password must be at least 8 characters long and include uppercase, lowercase, number, and special character.",
    };
    return messages[fieldName];
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim() !== "" && name === requiredFields[0]) {
      setErrorMessage("");
    }
  };

  const validatePasswordStrength = (password) => {
    const strongPasswordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return strongPasswordPattern.test(password);
  };

  const validateForm = useCallback(() => {
    for (const field of requiredFields) {
      if (formData[field].trim() === "") {
        const message = giveData(field);
        setErrorMessage(message);
        toast.error(<CustomToast message={message} type="error" />, {
          className: "custom-toast-bottom-right",  // Apply custom class for position
          autoClose: 5000,
          hideProgressBar: false,
        });
        return false;
      }
    }

    if (formData.password !== formData.confirmPassword) {
      const message = giveData("confirmPassword");
      setErrorMessage(message);
      toast.error(<CustomToast message={message} type="error" />, {
        className: "custom-toast-bottom-right",  // Apply custom class for position
        autoClose: 5000,
        hideProgressBar: false,
      });
      return false;
    }

    if (!validatePasswordStrength(formData.password)) {
      const message = giveData("passwordStrength");
      setErrorMessage(message);
      toast.error(<CustomToast message={message} type="error" />, {
          className: "custom-toast-bottom-right",  // Apply custom class for position
          autoClose: 5000,
          hideProgressBar: false,
        });
      return false;
    }

    return true;
  }, [formData, requiredFields, setErrorMessage]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      dispatch(register(formData));
    }
  };

  return (
    <div>
      <div className="signup-container">
        <div
          style={{
            backgroundImage: `url(${backGroundImage})`,
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          className="signup-bg"
        >
          <div>
            <img src={finalwhite} alt="finalwhite" />
            <h1>Get Justice</h1>
            <p>1000 hills solicitors</p>
          </div>
        </div>

        <div className="signup-detail">
          <div className="error-message" hidden>
            {errorMessage && <p>{errorMessage}</p>}
          </div>
          <p>
            <b>Hello!</b> Create an Account to get Started
          </p>
          <form id="signup-form" onSubmit={handleSubmit}>
            <div id="names">
              <div className="signup-field names" id="signfirstName">
                <input
                  placeholder="First Name"
                  name="firstName"
                  className="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                />
              </div>
              <div className="signup-field names">
                <input
                  placeholder="Last Name"
                  name="lastName"
                  className="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="signup-field" id="signEmail">
              <input
                placeholder="Email*"
                type="email"
                name="email"
                className="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>

            <div className="number">
              <div id="signidNo">
                <input
                  placeholder="ID/Tin/Passport Number*"
                  name="idNo"
                  className="idNo"
                  value={formData.idNo}
                  onChange={handleChange}
                />
              </div>

              <div id="signphoneNumber">
                <input
                  placeholder="Phone Number*"
                  name="phoneNumber"
                  className="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="signup-select" id="signpreferredContact">
              <select
                name="preferredContact"
                className="preferredContact"
                id="preferred"
                value={formData.preferredContact}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Preferred contact*
                </option>
                <option value="email">Email</option>
                <option value="phone">Phone</option>
              </select>
            </div>

            <br />

            <fieldset id="signaddress">
              <legend>Address:</legend>
              <div className="signup-field" style={{ width: "90%" }}>
                <input
                  placeholder="District/Akarere*"
                  name="district"
                  className="district"
                  value={formData.district}
                  onChange={handleChange}
                />
              </div>
              <div id="address-sub">
                <div className="signup-field address-register">
                  <input
                    placeholder="Sector/Umurenge*"
                    name="sector"
                    className="sector"
                    value={formData.sector}
                    onChange={handleChange}
                  />
                </div>
                <div className="signup-field address-register">
                  <input
                    placeholder="Cell/Akagari*"
                    name="cell"
                    className="cell"
                    value={formData.cell}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </fieldset>

            <div className="password">
              <div className="password-field">
                <input
                  type="password"
                  placeholder="Password"
                  className="pass"
                  name="password"
                  onChange={handleChange}
                  ref={passRef}
                />
                <img src={eye} alt="eye" ref={showPassRef} />
              </div>
              <div className="password-field">
                <input
                  type="password"
                  placeholder="Confirm password"
                  className="pass"
                  name="confirmPassword"
                  onChange={handleChange}
                  ref={confirmRef}
                />
                <img src={eye} alt="eye" ref={showConfirmRef} />
              </div>
            </div>

            <div id="buttons-id" className="buttons">
              {loading ? (
                <button id="signup-register-loading">
                  <span>
                    Registering...{" "}
                    <Oval
                      height={20}
                      width={20}
                      color="white"
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="darkblue"
                      strokeWidth={2}
                    />
                  </span>
                </button>
              ) : (
                <button id="signup-register" type="submit">
                  Register
                </button>
              )}

              <h2>
                Already a member? <Link to="/login">Sign-In</Link>
              </h2>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer /> {/* Add this line to display toast notifications */}
    </div>
  );
};

export default Signup;
