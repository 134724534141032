import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const markAsDone = createAsyncThunk(
  "markasdone/markcaseasdone",
  async ({ id, value }, thunkAPI) => {
    try {
      const token = localStorage.getItem('token');

      const response = await axios.patch(`${BACKEND_URL}/cases/${id}`, value, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log("all cases Data:", response.data);
      toast.success("case is marked as Done");
      return response.data.data;
    } catch (error) {
      console.log("Send Error:", error.response?.data || error.message);
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const status = createAsyncThunk(
  "status/markcaseasstatus",
  async ({ id, value1 }, thunkAPI) => {
    try {
      const token = localStorage.getItem('token');

      const response = await axios.patch(
        `${BACKEND_URL}/cases/status/${id}`,
        value1,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      // console.log("all cases Data:", response.data);
      toast.success("case status is changed");
      return response.data.data;
    } catch (error) {
      console.log("Send Error:", error.response?.data || error.message);
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const assigning = createAsyncThunk(
  "assigning/markcaseasassigning",
  async ({ id, value3 }, thunkAPI) => {
    try {
      const token = localStorage.getItem('token');

      const response = await axios.patch(
        `${BACKEND_URL}/cases/assigning/${id}`,
        value3,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      // console.log("all cases Data:", response.data);
      toast.success("case is assigne successfully");
      return response.data.data;
    } catch (error) {
      console.log("Send Error:", error.response?.data || error.message);
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const getLawyers = createAsyncThunk(
  "allLawyers/getAllLawyers",
  async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/users/lawyers`);
      console.log("list of lawyers", response.data);
      return response.data;
    } catch (error) {}
  }
);

const initialState = {
  casesData: [],
  lawyers: {},
  loading: false,
  error: null,
  success: false,
};

const markAsDoneSlice = createSlice({
  name: "MarkAsDone",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(markAsDone.pending, (state) => {
        state.casesData = [];
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(markAsDone.fulfilled, (state, action) => {
        state.casesData = action.payload || []; // Ensure it's an array
        state.success = true;
        state.loading = false;
        state.error = null;
      })
      .addCase(markAsDone.rejected, (state, action) => {
        state.casesData = [];
        state.loading = false;
        state.success = false;
        state.error = action.payload || "Failed to mark case as done";
      })
      .addCase(status.pending, (state) => {
        state.casesData = [];
        state.loading = true;
        state.error = null;
      })
      .addCase(status.fulfilled, (state, action) => {
        state.casesData = action.payload || []; // Ensure it's an array
        state.loading = false;
        state.success = true;

        state.error = null;
      })
      .addCase(status.rejected, (state, action) => {
        state.casesData = [];
        state.loading = false;
        state.error = action.payload || "Failed to mark case as done";
      })
      .addCase(assigning.pending, (state) => {
        state.casesData = [];
        state.loading = true;
        state.error = null;
      })
      .addCase(assigning.fulfilled, (state, action) => {
        state.casesData = action.payload || []; // Ensure it's an array
        state.loading = false;
        state.success = true;

        state.error = null;
      })
      .addCase(assigning.rejected, (state, action) => {
        state.casesData = [];
        state.loading = false;
        state.error = action.payload || "Failed to mark case as done";
      })
      .addCase(getLawyers.pending, (state) => {
        state.lawyers={};
      })
      .addCase(getLawyers.fulfilled, (state, action) => {
        state.lawyers = action.payload;
      })
      .addCase(getLawyers.rejected, (state, action) => {
        state.error = action.payload;
        state.lawyers = {};
      });
  },
});

export default markAsDoneSlice.reducer;
