import React, { useEffect, useRef } from "react";
import "./login.scss";
import backGroundImage from "../../assets/images/bg.png";

import finalwhite from "../../assets/images/finalwhite.png";
import eye from "../../assets/images/eye.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/slices/loginSlice";
import { ToastContainer } from "react-toastify";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, success, user } = useSelector((state) => state.login);
  const showPassRef = useRef(null);
  const passRef = useRef(null);

  const onHandlesubmit = async (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;
    const userData = { email, password };
    dispatch(login(userData));
  };
  useEffect(() => {
    if (success && user) {
      // Redirect to a different page or perform any action you need after login
      console.log("User imfo:", user.user.role);
      if (user.user.role === "adminStaff") {
        setTimeout(() => {
          navigate("/staff-dashboard");
        }, 1000);
      }
      if (user.user.role === "client") {
        setTimeout(() => {
          navigate("/user-dashboard");
        }, 1000);
      }
      if (user.user.role === "admin") {
        setTimeout(() => {
          navigate("/admin-dashboard");
        }, 1000);
      }
    }
  }, [success, user, navigate]);
  useEffect(() => {
    const showPass = showPassRef.current;
    const pass = passRef.current;

    const togglePasswordVisibility = () => {
      if (pass.type === "password") {
        pass.type = "text";
      } else {
        pass.type = "password";
      }
    };

    showPass.addEventListener("click", togglePasswordVisibility);

    // Cleanup the event listener on component unmount
    return () => {
      showPass.removeEventListener("click", togglePasswordVisibility);
    };
  }, []);

  return (
    <div className="login-container-new">
      <div
        style={{
          backgroundImage: `url(${backGroundImage})`,
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="login-bg"
      >
        <div>
          <img src={finalwhite} alt="finalwhite" />
          <h1>Get Justice</h1>
          <p>1000 hills solistors</p>
          {/* <button>Login</button> */}
        </div>
      </div>
      <div className="login-detail">
        <form id="login-form" onSubmit={onHandlesubmit}>
          <center>
            {" "}
            <h2>LOGIN</h2>
          </center>
          <div className="input-field">
            <input
              type="email"
              placeholder="Email*"
              className="email"
              name="email"
              required
            />
          </div>
          <div className="input-field">
            <input
              type="password"
              placeholder="Password*"
              className="pass"
              name="password"
              required
              ref={passRef}
            />
            <img
              src={eye}
              alt="Show Password"
              className="show-pass"
              ref={showPassRef}
            />
          </div>
          <div className="buttons">
            {loading ? (
              <button className="submit-button-loading">
                <span>
                  Logging In...{" "}
                  <Oval
                    height={20}
                    width={20}
                    color="white"
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="darkblue"
                    strokeWidth={2}
                  />
                </span>{" "}
              </button>
            ) : (
              <button className="submit-button" type="submit">
                Login
              </button>
            )}
            Or, If you don't have account🔻
            <Link to="/register">
              <button className="submit-button">Register</button>
            </Link>
          </div>
          <p>
            <Link to="/forgot-password">Forgot Password</Link>
          </p>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;