import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "./dash.scss";
import eye from "../../assets/images/eye.png";
import add from "../../assets/images/add.png";
import bg from "../../assets/images/law.jpg";
import Pagination from "../pagination/pagination";
import { Link } from "react-router-dom";
import { fetchCases, deleteCase } from "../../redux/slices/casesSlice";
import SingleCaseComponent from "../SingleCase/SingleCase";
import SingleCaseModal from "../singlecaseModal/singleModal";

const Content = ({ onToggleSubmitCase }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.deleteCase);
  const { addsuccess } = useSelector((state) => state.addCase);
  const { cases } = useSelector((state) => state.cases);
  
  useEffect(() => {
    if (addsuccess) {
      const userId = localStorage.getItem("userId");
      if (userId) {
        dispatch(fetchCases(userId));
      }
    }
  }, [addsuccess, dispatch]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const userId = localStorage.getItem("userId");
      if (userId) {
        dispatch(fetchCases(userId));
      }
    }
  }, [dispatch]);

  const [currentPage, setCurrentPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCaseId, setSelectedCaseId] = useState(null);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [caseToDelete, setCaseToDelete] = useState(null);

  const casesPerPage = 3;

  const handleDeleteCase = useCallback(() => {
    if (caseToDelete) {
      dispatch(deleteCase(caseToDelete)).then((result) => {
        if (result.error) {
          toast.error("Failed to delete case");
        } else {
          toast.success("Case deleted successfully");

          const userId = localStorage.getItem("userId");
          if (userId) {
            dispatch(fetchCases(userId));
          }
        }
        setIsConfirmModalVisible(false);
      });
    }
  }, [dispatch, caseToDelete]);

  const openModal = (caseId) => {
    setSelectedCaseId(caseId);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedCaseId(null);
  };

  const confirmDelete = (caseId) => {
    setCaseToDelete(caseId);
    setIsConfirmModalVisible(true);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalVisible(false);
    setCaseToDelete(null);
  };

  const totalPages = Math.ceil((cases?.length || 0) / casesPerPage);
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const indexOfLastCase = currentPage * casesPerPage;
  const indexOfFirstCase = indexOfLastCase - casesPerPage;
  const currentCases = cases?.slice(indexOfFirstCase, indexOfLastCase) || [];

  const truncateSummary = (summary, charLimit) => {
    return summary.length <= charLimit ? summary : summary.slice(0, charLimit) + "...";
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  };

  const caseLength = cases.length;

  return (
    <div className="cases-table">
      <h3>Added Cases [{caseLength}]</h3>
      {caseLength === 0 ? (
        <div className="add-case">
          <h1>
            No Cases Added{" "}
            <img src={add} alt="add case" onClick={onToggleSubmitCase} />{" "}
          </h1>
        </div>
      ) : (
        <>
          <div className="added-cases">
            {currentCases.map((caseItem, index) => (
              <div className="case-card" key={index}>
                <div
                  className="case-card-header"
                  style={{
                    backgroundImage: `url(${bg})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div className="view">
                    <img
                      src={eye}
                      alt="eye"
                      onClick={() => openModal(caseItem._id)}
                    />
                    <p>View</p>
                  </div>
                </div>
                {isModalVisible && selectedCaseId === caseItem._id && (
                  <SingleCaseModal onClose={closeModal}>
                    <SingleCaseComponent caseId={selectedCaseId} />
                  </SingleCaseModal>
                )}
                <div className="case-body">
                  <p>
                    <b>Email:</b> <span>{caseItem.caseOwner}</span>
                  </p>
                  <p>
                    <b>CaseType:</b> <span>{caseItem.caseType}</span>
                  </p>
                  <p>
                    <b>CaseSubType:</b> <span>{caseItem.caseSubtype}</span>
                  </p>
                  <p>
                    <b>Summary:</b>{" "}
                    <span>{truncateSummary(caseItem.summary, 50)}</span>
                  </p>
                  <p>
                    <b>File: </b>
                    <a
                      href={caseItem.file}
                      download
                      className="download-button"
                    >
                      Download
                    </a>
                  </p>
                  <p>
                    <b>Status:</b> <span>{caseItem.status}</span>
                  </p>
                  <p>
                    <b>CreatedAt:</b>
                    <span className="time">
                      {formatDate(caseItem.createdAt)}
                    </span>
                  </p>
                  <div className="actions">
                    <button className="edit">
                      <Link to={`/update-case/${caseItem._id}`} className="link">
                        <span>Edit</span>
                      </Link>
                    </button>
                    {loading ? (
                      <button className="delete">Deleting .....</button>
                    ) : (
                      <button
                        className="delete"
                        onClick={() => confirmDelete(caseItem._id)}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      )}

      {isConfirmModalVisible && (
        <div className="confirm-modal">
          <div className="confirm-modal-content">
            <p>Are you sure you want to delete this case?</p>
            <button className="confirm-button" onClick={handleDeleteCase}>
              Yes, Delete
            </button>
            <button className="cancel-button" onClick={closeConfirmModal}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Content;
