import React from 'react';
import './adminSingleModal.scss';

const AdminSingleCaseModal = ({ children, onClose }) => {
  return (
    <div className="single-case-modal-overlay">
      <div className="single-case-modal-content">
        <div onClick={onClose} className="single-case-modal-close-button">
          &times;
        </div>
        {children}
      </div>
    </div>
  );
};

export default AdminSingleCaseModal;
