import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const getAllCases = createAsyncThunk(
  'getCases/getAllCases',
  async (_, thunkAPI) => {
    try {
      const token = localStorage.getItem('token');
      
      const response = await axios.get(`${BACKEND_URL}/cases/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log("all cases Data:", response.data);
      return response.data.data;
    } catch (error) {
      console.log("Send Error:", error.response?.data || error.message);
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

const initialState = {
  casesData: [],
  loading: false,
  error: null
};

const getAllCasesSlice = createSlice({
  name: "getAllCases",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCases.pending, (state) => {
        state.casesData = [];
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllCases.fulfilled, (state, action) => {
        state.casesData = action.payload || []; // Ensure it's an array
        state.loading = false;
        state.error = null;
      })
      .addCase(getAllCases.rejected, (state, action) => {
        state.casesData = [];
        state.loading = false;
        state.error = action.payload || 'Failed to fetch cases';
      });
  }
});

export default getAllCasesSlice.reducer;
