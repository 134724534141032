import React from "react";
import "./services.scss";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <div>
      <div className="service-body">
        <div className="service-section">
          <div className="heading">
            <h1>Services</h1>
            <hr />
          </div>
          <div className="cards">
            <div className="card1 card">
              <h3>Consultance</h3>
              <h4>Tax consultance</h4>
              <br />

              <div className="home-buttons">
                <button>get consultance</button>
              </div>
            </div>
            <div className="card2 card">
              <h3>Advocate Bridge</h3>
              <h4>Linking people with appropriate advocate</h4>
              <br />

              <div className="home-buttons">
                <button>
                  <Link to="/login" style={{ textDecoration: "none" }}>
                    SubmitCase
                  </Link>
                </button>
              </div>
            </div>
            <div className="card3 card">
              <h3>Educational support</h3>
              <h4>Interbership</h4>
              <br />

              <div className="home-buttons">
                <button>get internership</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
