import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleCase } from "../../redux/slices/singleCaseSlice";
import bg from "../../assets/images/law.jpg";
import download from "../../assets/images/download.png";

const SingleCaseComponent = ({caseId}) => {
  const dispatch = useDispatch();
  const { singleCaseData } = useSelector(
    (state) => state.singleCase
  );

  useEffect(() => {
    if (caseId) {
      dispatch(getSingleCase(caseId));
    }
  }, [dispatch, caseId]);

  return (
    <div>
      <div
        className="wrapper-all-single"
        style={{ padding: "20px", backgroundColor: "fff", height: "auto" }}
      >
        <div
          className="logo"
          style={{
            marginBottom: "20px",
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",

            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "150px",
          }}
        >
          <div style={{ margin: "auto" }}>
            <h1 style={{ textAlign: "center", textDecoration: "underline" }}>
              SUBMITTED CASE:
            </h1>
          </div>
        </div>
        <div
          className="siglecase-data"
          style={{
            width: "90%",
            color: "#021742",
            margin: "0 auto",
          }}
        >
          {Object.keys(singleCaseData).length > 0 ? (
            <>
              <h3>Case Owner:</h3>
              <div className="caseOwner fitalic">
                {singleCaseData.caseOwner}
              </div>
              <h3>Case Type:</h3>
              <div className="casetype fitalic">{singleCaseData.caseType}</div>
              <h3>Case SubType:</h3>
              <div className="caseSubType fitalic">
                {singleCaseData.caseSubtype}
              </div>
              <h3>Case Summary:</h3>
              <div className="caseSummary fitalic">
                {singleCaseData.summary}
              </div>
              <h1>Submitted File</h1>

              <a
                href={singleCaseData.file}
                style={{ display: "flex", alignItems: "center" }}
              >
                <img src={download} alt="download" />
                Download
              </a>
            </>
          ) : (
            <p>No case data available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleCaseComponent;
