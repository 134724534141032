import React, { useEffect, useState } from "react";
import "./cases.scss";
import Pagination from "../pagination/pagination";
import ActionsModal from "../actionsModal/actionsModal";
import Actions from "./actions";
import AdminSingleCase from "./adminsingleCase";
import AdminSingleCaseModal from "../adminSinglemodal/adiminSingleCase";
import { useDispatch, useSelector } from "react-redux";
import { getAllCases } from "../../redux/slices/getAllCasesSlice";

const SubmitedCases = () => {
  const dispatch = useDispatch();
  const { success } = useSelector((state) => state.done);

  const { casesData } = useSelector((state) => state.allCases);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [selectedCaseId, setSelectedCaseId] = useState(null);
  const casesPerPage = 10;

  useEffect(() => {
    dispatch(getAllCases());
  }, [dispatch]);

  const openModal = (caseId) => () => {
    setSelectedCaseId(caseId);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedCaseId(null); // Clear the selected case when modal is closed
  };

  const openModal2 = (caseId) => () => {
    setSelectedCaseId(caseId);
    setIsModalVisible2(true);
  };

  const closeModal2 = () => {
    setIsModalVisible2(false);
  };

  const totalPages = Math.ceil(casesData?.length / casesPerPage) || 0;

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const sortedCases = casesData?.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) || [];
  const indexOfLastCase = currentPage * casesPerPage;
  const indexOfFirstCase = indexOfLastCase - casesPerPage;
  const currentCases = sortedCases.slice(indexOfFirstCase, indexOfLastCase);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        dispatch(getAllCases());
        closeModal();
      }, 5001);
    }
  }, [success, dispatch]);

  return (
    <div className="cases-table-view">
      <table>
        <thead>
          <tr>
            <th className="th">Case Id</th>
            <th className="th">Case Owner</th>
            <th className="th">Case Type</th>
            <th className="th">
              Done /
              <br />
              Archived
            </th>
            <th className="th">Assigned To</th>
            <th className="th">Status</th>
            <th className="th">Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentCases.map((caseItem) => (
            <tr key={caseItem._id}>
              <td className="td case-id">{caseItem._id}</td>
              <td className="td">{caseItem.caseOwner}</td>
              <td className="td">{caseItem.caseType}</td>
              <td className="td">{caseItem.markAsDoneArchived}</td>
              <td className="td">{caseItem.assignedTo}</td>
              <td
                className="td"
                style={{
                  color: caseItem.status === "pending" ? "red" : "green",
                }}
              >
                {caseItem.status}
              </td>
              <td className="actions">
                <button onClick={openModal2(caseItem._id)}>View</button>
                {isModalVisible2 && selectedCaseId === caseItem._id && (
                  <AdminSingleCaseModal onClose={closeModal2}>
                    <AdminSingleCase caseId={selectedCaseId} />
                  </AdminSingleCaseModal>
                )}
                <button>Edit</button>
                <button>Delete</button>
                <button onClick={openModal(caseItem._id)}>Actions</button>
                {isModalVisible && selectedCaseId === caseItem._id && (
                  <ActionsModal onClose={closeModal}>
                    <Actions caseId={selectedCaseId} />
                  </ActionsModal>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default SubmitedCases;
