// src/redux/slices/singleCaseSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const getSingleCase = createAsyncThunk(
  'singleCase/getSingleCase',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${BACKEND_URL}/cases/singleCase/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      console.log(response.data)
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "Failed to fetch case");
    }
  }
);

const initialState = {
  singleCaseData: {},
  loading: false,
  error: null
};

const singleCaseSlice = createSlice({
  name: 'singleCase',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSingleCase.pending, (state) => {
        state.loading = true;
        state.singleCaseData = {};
        state.error = null;
      })
      .addCase(getSingleCase.fulfilled, (state, action) => {
        state.loading = false;
        state.singleCaseData = action.payload;
        state.error = null;
      })
      .addCase(getSingleCase.rejected, (state, action) => {
        state.loading = false;
        state.singleCaseData = {};
        state.error = action.payload;
      });
  }
});

export default singleCaseSlice.reducer;
