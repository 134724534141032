import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const updateUser = createAsyncThunk(
  "usersData/usersData",
  async ({userId,data}, { rejectWithValue }) => {
    console.log("id to update",userId)
    try {
      const token = localStorage.getItem('token');
      console.log(token,"token from logged user")
      const response = await axios.put(`${BACKEND_URL}/users/${userId}`,data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("single user", response.data.data);
      return response.data.data;
    } catch (error) {
      console.error("Fetch Error:", error.response?.data || error.message);
      return rejectWithValue(error.response?.data || 'Failed to get user data');
    }
  }
);

const initialState = {
  cases: [],
  loading: false,
  success:false,
  error: null,
};

export const updateUserSlice = createSlice({
  name: "updateUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        
        
        state.loading = false;
        state.error = null;
        state.success=true;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.error = action.payload;
        
        state.loading = false;
      });
  },
});

export default updateUserSlice.reducer;
