import React from "react";
import finalWhite from "../../assets/images/finalwhite.png";
import '../Login/login.scss';
import backgroundImage from '../../assets/images/bg.png';
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../redux/slices/forgetPassSlice";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Oval } from "react-loader-spinner";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.reset);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const email = event.target.email.value;

    dispatch(resetPassword({ email })).then((response) => {
      if (response.meta.requestStatus === "fulfilled") {
        toast.success("Password reset email sent successfully!");
      } else {
        toast.error(error|| "Failed to send password reset email. Check your emailOr try Again");
      }
    });
  };

  return (
    <div className='login-container-new'>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="login-bg"
      >
        <div>
          <img src={finalWhite} alt="finalwhite" />
          <h1>Get Justice</h1>
          <p>1000 Hills Solicitors</p>
        </div>
      </div>
      <div className="login-detail">
        <div className="alert">
          <p>If you forget your password, use the form below to reset it.</p>
        </div>
        <form id="forget-pass-form" onSubmit={handleSubmit}>
          <center><h3>Enter Your Email</h3></center>
          <div className="input-field">
            <input
              type="email"
              placeholder="Email*"
              className="email"
              name="email"
              required
              disabled={loading} // Disable the input while loading
            />
            <button className="submit-button" type="submit" disabled={loading}>
              {loading ? (<span>
                    Verifying...{" "}
                    <Oval
                      height={20}
                      width={20}
                      color="white"
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="darkblue"
                      strokeWidth={2}
                    />
                  </span>) : "Send Email"}
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ForgotPassword;
